// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProviderWrapper } from './contexts/ThemeContext'; // Importer le wrapper du thème
import './i18n'; // Importer la configuration d’i18next
import { CssBaseline } from '@mui/material';
import './index.css'; //
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();


// Créer un conteneur
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

// Rendre l'application
root.render(
  <ThemeProviderWrapper>
    <CssBaseline /> {/* Assure que les styles de base sont appliqués */}
    <App />
  </ThemeProviderWrapper>
);
