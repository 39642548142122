import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import News1 from '../assets/images/News1.jpeg'; 
import News2 from '../assets/images/News2.jpeg'; 
import News3 from '../assets/images/News3.jpg'; 
import soutenance from '../assets/images/soutenance.jpg'; 
import foot from "../assets/images/foot.jpg";
import getec from '../assets/images/getec.jpg';

// NewsCard component
const NewsCard = ({ title, date, description, imageUrl }) => {
  return (
    <Card sx={{ maxWidth: '100%', margin: 'auto', transition: '0.3s', '&:hover': { transform: 'scale(1.05)' } }}>
      <CardMedia component="img" height="370" image={imageUrl} alt={title} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography gutterBottom variant="h6" component="div" sx={{ color: 'gray' }}>
          {date}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

// ArticlesSection component to display articles
const ArticlesSection = () => {

  return (
    <Box sx={{ padding: 5 }}>
      
      <Grid container spacing={4}>
        
        <Grid item xs={12}>
          <iframe
            title="Video News"
            src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FMTNCAMEROON%2Fvideos%2F181638818005816%2F&show_text=true&width=560&t=1500"
            width="100%"
            height="700"
            style={{ border: 'none' }}
            scrolling="no"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </Grid>
      </Grid>
    </Box>
  );
};

// NewssSection component to display news
const NewssSection = () => {
  const { t } = useTranslation();

  //  news articles with images
  const images = [soutenance, News3, getec, News1, News2, foot];

  // Retrieve news items from translation
  const newsItems = t('newsSection.newsItems', { returnObjects: true });

  return (
    <>
      {/* Helmet for SEO optimization */}
      <Helmet>
        <title>{t('newsSection.title')} - William Lowe</title>
        <meta name="description" content="Discover the latest news and significant events of William Lowe, a student in cybersecurity and web development." />
        <meta name="keywords" content="William Lowe, news, events, cybersecurity, web development" />
        <meta property="og:title" content={`${t('newsSection.title')} - William Lowe`} />
        <meta property="og:description" content="Discover the latest news and significant events of William Lowe, a student in cybersecurity and web development." />
      </Helmet>

      <Box data-aos="" sx={{ padding: 2 }}>
       
        <header className="text-center mb-5">
        <Typography   variant=  "h2" component="h2" className="display-4" color="success.main" gutterBottom>
            {t('newsSection.title')}
          </Typography>
          </header>
        <Grid container spacing={4}>
          {newsItems.map((news, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <NewsCard
                title={news.title}
                date={news.date}
                description={news.description}
                imageUrl={images[index]} // Assigning images based on index
              />
            </Grid>
          ))}
        </Grid>
        <ArticlesSection />
      </Box>
    </>
  );
};

export default NewssSection;
