import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box, Typography } from '@mui/material';

const About = () => {
  const { t } = useTranslation();
  
  const skills = t('skills_detail', { returnObjects: true });
  const education = t('educations', { returnObjects: true });
  const experience = t('experience_items', { returnObjects: true });

  const handleDownloadClick = () => {
    const link = document.createElement('a');
    link.href = '/WILLIAM_LOWE.pdf';
    link.download = 'WILLIAM_LOWE.pdf';
    link.click();
  };

  return (
    <>
      <Helmet>
        <title>{t('about_title')}</title>
        <meta name="description" content={t('description')} />
        <meta name="keywords" content="William Lowe, cybersecurity, web development, skills, education, professional experience" />
        <meta property="og:title" content={t('about_title')} />
        <meta property="og:description" content={t('description')} />
      </Helmet>

      <div className="container py-5"  data-aos="">
        <header className="text-center mb-5">
        <Typography   variant=  "h2" component="h1" className="display-4" color="success.main" gutterBottom>
            {t('about')}
          </Typography>
      
          <p className="lead">{t('about_intro')} <span className="fw-bold">{t('about_future_intern')}</span></p>
       
        <Typography variant="p" component="p" color="black.main" className="text">
        {t('about_description')}
        </Typography>
        </header>
         
       
        <Box className="row">
          {/* Skills Section */}
          
          <div className="col-md-6 mb-4">
            <div className="card No-shadow p-3">
            <h2 className="h4 text-success">{t('skills')}</h2>
              <ul className="list-group list-group-flush">
                {skills.map((skill) => (
                  
                  <li key={skill.name} className="list-group-item d-flex justify-content-between align-items-center">
                    <span>{t(skill.name)}</span>
        
                  </li>
                 
                ))}
              </ul>
              <button onClick={handleDownloadClick} className="btn btn-success w-100 mt-3">{t('download_cv')}</button>
            </div>
          </div>

          {/* Education Section */}

          <div className="col-md-6 mb-4">
            <div className="card shadow-sm p-3">
              <h2 className="h4 text-success">{t('education')}</h2>
              <ul className="list-group list-group-flush">
                {education.map((edu) => (
                  <li key={edu.degree} className="list-group-item">
                    <p className="fw-bold">{edu.date}</p>
                    <p>{edu.degree}</p>
                    {edu.description && <p className="text-muted">{edu.description}</p>}
                    <p className="small text-muted">{edu.institution}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Box>
        

        {/* Experience Section */}
        <div className="card shadow-sm p-3 mt-4">
          <h2 className="h4 text-success">{t('experience')}</h2>
          <ul className="list-group list-group-flush">
            {experience.map((exp) => (
              <li key={exp.role} className="list-group-item">
                <p className="fw-bold">{exp.date}</p>
                <p>{exp.role}</p>
                <p className="text-muted">{exp.company}</p>
                <p>{exp.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default About;